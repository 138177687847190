import 'core-js/fn/object/assign'
import domready from 'domready'
import getSize from 'get-size'

function resizeSlider () {
  if (document.getElementById('slider')) {
    let slide = document.getElementById('slider').getElementsByClassName('swiper-slide-active')
    var slides = document.getElementsByClassName('slider1')
    if (slide[0]) {
      if (!window.matchMedia('(min-width: 568px)').matches) {
        for (let i = 0; i < slides.length; i++) {
          let sizeContainer = getSize(slides[i].getElementsByClassName('slider-icon')[0])
          let sizeBack = getSize(slides[i].getElementsByClassName('slider-background')[0])
          let sizeMin = getSize(slides[i].getElementsByClassName('slider-icon-img')[0])
          let left = 0
          if (sizeContainer.width > sizeBack.width) {
            left = sizeContainer.width - parseFloat(sizeBack.width)
            left = left / 2
            left = sizeMin.width - (left + (sizeContainer.width))
            left = '-' + left + 'px'
          } else {
            left = '-50%'
          }
          let top = sizeMin.height - parseFloat(sizeBack.height)
          top = top / 2
          slides[i].getElementsByClassName('slider-background-blur')[0].style.width = sizeMin.width + 'px'
          slides[i].getElementsByClassName('slider-background-blur')[0].style.height = sizeMin.height + 'px'
          slides[i].getElementsByClassName('slider-background-blur')[0].style.top = '-' + top + 'px'
          slides[i].getElementsByClassName('slider-background-blur')[0].style.left = left
        }
      } else {
        /* let size = getSize(slide[0])
         for (let i = 0; i < slides.length; i++) {
         let sizeBack = getSize(slides[i].getElementsByClassName('slider-background')[0])
         let top = size.height - parseFloat(sizeBack.height)
         top = top / 2
         let left = size.width - parseFloat(sizeBack.width)
         left = left / 2
         slides[i].getElementsByClassName('slider-background-blur')[0].style.width = size.width + 'px'
         slides[i].getElementsByClassName('slider-background-blur')[0].style.height = size.height + 'px'
         slides[i].getElementsByClassName('slider-background-blur')[0].style.top = '-' + top + 'px'
         slides[i].getElementsByClassName('slider-background-blur')[0].style.left = '-' + left + 'px'
         }*/
        let size = getSize(slide[0].getElementsByClassName('slider-icon-img')[0])
        let sizeBox = getSize(slide[0])
        for (let i = 0; i < slides.length; i++) {
          let sizeBack = getSize(slides[i].getElementsByClassName('slider-background')[0])
          let top = size.height - parseFloat(sizeBack.height)
          top = top / 2

          let left = parseFloat(size.width) - parseFloat(sizeBox.width)
          left = left / 2
          left = ((parseFloat(sizeBox.width) - parseFloat(sizeBack.width)) / 2)
          slides[i].getElementsByClassName('slider-background-blur')[0].style.width = size.width + 'px'
          slides[i].getElementsByClassName('slider-background-blur')[0].style.height = size.height + 'px'
          slides[i].getElementsByClassName('slider-background-blur')[0].style.top = '-' + top + 'px'
          slides[i].getElementsByClassName('slider-background-blur')[0].style.left = '-' + left + 'px'
        }
      }
    }
  }
  clearTimeout()
}

/* function equalHeights (className) {
 var findClass = document.getElementsByClassName(className)
 var tallest = 0
 // Loop over matching divs
 for (let i = 0; i < findClass.length; i++) {
 var ele = findClass[i]
 var eleHeight = ele.offsetHeight
 tallest = (eleHeight > tallest ? eleHeight : tallest)
 /!* look up ternary operator if you dont know what this is *!/
 }
 for (let i = 0; i < findClass.length; i++) {
 findClass[i].style.height = tallest + 'px'
 }
 }*/

/* function insertAfter (referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

function newsBox () {
  let news = document.getElementById('news-2')
  let newsHtml = document.getElementById('news-2').outerHTML
  news.parentNode.removeChild(news)

  if (window.matchMedia('(min-width: 568px)').matches) {
    insertAfter(newsHtml, document.getElementById('news-1'))
  }
  if (window.matchMedia('(min-width: 880px)').matches) {
    insertAfter(newsHtml, document.getElementById('kurs-box'))
  }
  if (window.matchMedia('(min-width: 1280px)').matches) {
    insertAfter(newsHtml, document.getElementById('news-1'))
  }
}*/
export default function elementsize () {
  domready(() => {
    setTimeout(resizeSlider, 4)
    setTimeout(resizeSlider, 300)
    setTimeout(resizeSlider, 3000)
    window.addEventListener('resize', function () {
      resizeSlider()
    })
   /* window.addEventListener('resize', function () {
      newsBox()
    })*/
  })
}
