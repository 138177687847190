import selectAll from './select-all'
import xhr from 'xhr'

function getKurs (id, element) {
  let data = {
    kursID: id,
    modus: 'getKursBody'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    let data = JSON.parse(body)
    document.getElementById('kurs-body').innerHTML = data.html
    modal(element)
    console.log(err)
  })
}
function modal (elem) {
  let closeButton = selectAll('.close')
  elem.classList.remove('hidden')
  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[i].addEventListener('click', function () {
        elem.classList.add('hidden')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) {
          window.location.reload()
        }
      }, false)
    }
  }
}
export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[i].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById(this.getAttribute('data-target'))
        if (this.classList.contains('modal-kurs')) {
          getKurs(this.getAttribute('data-id'), element)
        } else {
          modal(element)
        }

        return false
      }, {passive: false})
    }
  }
}
