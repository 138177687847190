/**
 * Created by Tom on 10.11.2016.
 */

import selectAll from './select-all'

function toggleContainer (elem, tabs) {
  let aktID = elem.getAttribute('data-id')
  let tabContent = selectAll('div.kurse-content')
  let tab = selectAll('span.kurse-date')
  let setTab = false
  if (!document.getElementById(aktID).classList.contains('kurse-open')) {
    setTab = true
  }
  for (let y = tabContent.length - 1; y >= 0; y--) {
    tabContent[ y ].classList.remove('kurse-open')
  }
  for (let z = tab.length - 1; z >= 0; z--) {
    tab[ z ].classList.remove('active')
  }
  if (setTab) {
    document.getElementById(aktID).classList.add('kurse-open')
    elem.classList.add('active')
  }
}

export default function initToggle () {
  let toggleLink = selectAll('.kurse-date')
  if (toggleLink !== null) {
    for (let i = toggleLink.length - 1; i >= 0; i--) {
      toggleLink[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        toggleContainer(this, toggleLink)
        return false
      })
    }
  }
}
